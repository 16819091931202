<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="1" @change="changeMenu"></header-view>
		<right-tool :class="{'show': btnFlag}"></right-tool>
		<div class="detail-wrap">
			<div class="title-wrap flex-col" v-if="newInfo">
				<h1 class="ft-30">{{newInfo.title}}</h1>
				<div class="row flex-row ft-14">
					<p>来源：{{newInfo.source_dictText}}</p>
					<p class="time" v-if="newInfo.updateTime">{{newInfo.updateTime.substring(0,10)}}</p>
					<p class="time" v-else>{{newInfo.createTime.substring(0,10)}}</p>
				</div>
			</div>
			<div class="html-wrap" v-if="newInfo" v-html="newInfo.content"></div>
		</div>
		<div class="paging-wrap" v-if="newInfo">
			<div class="w80 flex-row" :class="{'downUp': newInfo.up && newInfo.down}">
				<div class="prev-wrap flex-col ft-16" v-if="newInfo.up" @click="getDeatil(newInfo.up.id)">
					<p>上一篇</p>
					<p>{{newInfo.up.title}}</p>
				</div>
				<div class="next-wrap flex-col ft-16" v-if="newInfo.down" @click="getDeatil(newInfo.down.id)">
					<p>下一篇</p>
					<p>{{newInfo.down.title}}</p>
				</div>
			</div>
		</div>
		<div class="back-wrap flex-row" @click="backTap">
			<img src="../../assets/images/back_icon.png" >
		</div>
	</div>
</template>

<script>
	import headerView from '@/components/header/headerView.vue'
	import rightTool from '@/components/rightTool/index.vue'
	import $ from 'jquery'
	import { newsDetail } from '@/api/api.js'
	export default{
		components: {
			headerView,
			rightTool
		},
		data() {
			return {
				minBar: false,
				showMenu: false,
				btnFlag: false,
				newInfo: null
			}
		},
		created() {
			if (this.$route.query.id) {
				this.getDeatil(this.$route.query.id)
			}
		},
		mounted() {
			let that = this
			document.body.style.cssText = "height: 100%;"
			window.addEventListener('scroll',that.handleScroll)
		},
		methods: {
			backTap() {
				this.$router.back()
			},
			changeMenu(bool) {
				this.showMenu = !bool
			},
			handleScroll(e){
				e.preventDefault()
				var scroH = $(document).scrollTop();  //滚动高度
				var viewH = $(window).height();  //可见高度
				var contentH = $(document).height();  //内容高度
				if(scroH > 50){  //距离顶部大于100px时
					this.minBar = true
					this.btnFlag = true
				}
				if (scroH < 50) {
					this.minBar = false
					this.btnFlag = false
				}
				if (contentH - (scroH + viewH) <= 100){  //距离底部高度小于100px
					console.log('距离底部高度小于100px')
				} 
				if (contentH == (scroH + viewH)){  //滚动条滑到底部啦
					console.log('滚动条滑到底部啦')
				}
			},
			getDeatil(id) {
				document.documentElement.scrollTop = 0
				newsDetail({id:id}).then(res =>{
					if (res.success) {
						this.newInfo = res.result
					} else {
						this.$message.error('获取新闻详情失败')
					}
				})
			}
		}
	}
</script>
<style scoped>
	.html-wrap /deep/ img{
		max-width: 100%;
		height: auto;
	}
</style>
<style lang="scss" scoped>
	.container{
		min-height: 100vh;
		padding-top: 16vh;
		nav.on{
			padding: 0.5% 2%;
			background: rgba(0,0,0,.6);
			box-shadow: 0 7px 32px -12px rgba(0,0,0,.9);
		}
		.detail-wrap{
			width: 72%;
			margin: 0 auto;
			.title-wrap{
				height: 140px;
				padding: 30px 0;
				color: #FFFFFF;
				justify-content: space-between;
				.row{
					margin-top: 20px;
					.time{margin-left: 30px;}
				}
			}
			/deep/ .html-wrap{
				width: 100%;
				min-height: 60vh;
				overflow: hidden;
				color: #FFF;
				font-size: 16px;
				h1, h2, h3, h4, h5, h6{
					font-weight: bold;
				}
				p{
					font-size: 16px;
					color: #FFF;
				}
			}
		}
		.paging-wrap{
			width: 100%;
			padding: 20px 0;
			color: #6D7277;
			background-color: #000000;
			.w80{
				width: 72%;
				margin: 0 auto;
				&.downUp{
					justify-content: space-between;
				}
				.prev-wrap,.next-wrap{
					width: 40%;
				}
				.ft-16{
					p{
						cursor: pointer;
						&:last-child{
							margin-top: 10px;
						}
					}
				}
			}
		}
		.back-wrap{
			position: fixed;
			right: 8%;
			bottom: 20%;
			width: 3.5vw;
			height: 3.5vw;
			background-color: #2E2E2E;
			border-radius: 50%;
			img{
				width: 20px;
				height: 17px;
			}
		}
	}
</style>
